@charset "UTF-8";
/* ==============================================================================================
   TinyMCE4 custom styles
   ============================================================================================== */
/* Extend styles
   ========================================================================== */
@media screen and (max-width: 670px) {
	.ph-sct-otr {
		padding-right: 20px;
		padding-left: 20px;
	}
}

@media screen and (min-width: 671px) {
	.ph-sct-otr {
		padding-right: 60px;
		padding-left: 60px;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.ph-sct-otr {
		padding-right: 40px;
		padding-left: 40px;
	}
}

/* Icon styles
   ========================================================================== */
@font-face {
	src: url("../fonts/icomoon.ttf?fmumff") format("truetype"), url("../fonts/icomoon.woff?fmumff") format("woff"), url("../fonts/icomoon.svg?fmumff#icomoon") format("svg");
	font-family: 'icomoon';
	font-style: normal;
	font-weight: normal;
	font-display: block;
}

.icn {
	font-family: 'icomoon';
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	line-height: 1;
	speak: none;
	text-transform: none;
}

.icn.-blnk::before {
	content: '\e900';
}

.icn.-arw::before {
	content: '\e901';
}

.icn.-crs::before {
	content: '\e902';
}

.icn.-lst::before {
	content: '\e903';
}

/* Editor styles
   ========================================================================== */
.mce-content-body {
	padding: 2em 4%;
	font-family: "Poppins", "YakuHanJP_Noto", "Noto Sans JP", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", YuGothic, "游ゴシック体", "Yu Gothic", "游ゴシック", Meiryo, "メイリオ", Osaka, "MS PGothic", "ＭＳ Ｐゴシック", sans-serif;
	font-size: 1.6rem;
	font-weight: 500;
	line-height: 1.9;
	text-align: justify;
	text-justify: inter-ideograph;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

/* General styles
   ========================================================================== */
.mce-content-body * + p,
.is-use-tinymce * + p {
	margin-top: 1.25em;
}

.mce-content-body .tr-tbl {
	display: none;
}

.prm-box-ttl {
	color: #fff;
	padding: 0.41176em 0.73529em;
	background-color: #031f6f;
}

@media screen and (max-width: 670px) {
	.prm-box-ttl {
		font-size: 1.71429em;
	}
	* + .prm-box-ttl {
		margin-top: 1.25em;
	}
}

@media screen and (min-width: 671px) {
	.prm-box-ttl {
		font-size: 2.125em;
	}
	* + .prm-box-ttl {
		margin-top: 1.76471em;
	}
}

.scnd-box-ttl {
	color: #fff;
	text-align: center;
	padding: 0 0.73529em;
	background-color: #031f6f;
}

.-career .scnd-box-ttl {
	background-color: #0f75be;
}

.-handicapped .scnd-box-ttl,
.-other .scnd-box-ttl {
	background-color: #4082ff;
}

@media screen and (max-width: 670px) {
	.scnd-box-ttl {
		font-size: 1.71429em;
	}
}

@media screen and (min-width: 671px) {
	.scnd-box-ttl {
		font-size: 2.125em;
	}
}

.mrkr-ttl {
	color: #fff;
	line-height: 1.8;
}

.mrkr-ttl_txt {
	background-color: #031f6f;
}

@media screen and (max-width: 670px) {
	.mrkr-ttl {
		font-size: 1.71429em;
	}
}

@media screen and (min-width: 671px) {
	.mrkr-ttl {
		font-size: 3.375em;
	}
}

/* Accordion styles
   ========================================================================== */
.prm-acrd_trg {
	padding: 0.41667em 0;
	color: #031f6f;
	font-size: 1.5em;
	font-weight: 700;
	display: flex;
	align-items: center;
}

.prm-acrd_trg-txt {
	display: block;
}

.mce-content-body .prm-acrd_trg {
	pointer-events: none;
}

.prm-acrd_tgl {
	width: 1.25em;
	height: 1.25em;
	margin-right: 0.625em;
	text-align: center;
	background-color: currentColor;
	overflow: hidden;
	display: block;
	flex-shrink: 0;
	align-self: baseline;
	position: relative;
}

.prm-acrd_tgl::before {
	content: '\e902';
	color: #fff;
	font-size: 1.25em;
	font-family: 'icomoon';
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	line-height: 1;
	speak: none;
	text-transform: none;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
}

.is-load .prm-acrd_tgl::before {
	transition: transform 450ms cubic-bezier(0.22, 1, 0.36, 1);
}

.is-opn .prm-acrd_tgl::before,
.mce-content-body .prm-acrd_tgl::before {
	transform: translate3d(-50%, -50%, 0) rotate(45deg);
}

.prm-acrd_cnt {
	background-color: #f1f1f1;
}

.is-use-tinymce .prm-acrd_cnt {
	display: none;
}

@media screen and (max-width: 670px) {
	* + .prm-acrd {
		margin-top: 1.42857em;
	}
	.prm-acrd_cnt {
		padding: 1.42857em;
	}
}

@media screen and (min-width: 671px) {
	* + .prm-acrd {
		margin-top: 1.875em;
	}
	.prm-acrd_cnt {
		padding: 1.875em;
	}
}

/* Philosophy styles
   ========================================================================== */
.ph-slgn {
	max-width: 62.5em;
	margin: 0 auto;
}

.ph-intr {
	font-size: 1.25em;
	font-weight: 700;
	text-align: center;
}

@media screen and (max-width: 425px) {
	.ph-intr {
		font-size: 4.11765vw;
	}
}

.ph-sct {
	max-width: 56.25em;
	margin: 0 auto;
	color: #031f6f;
	border: solid #031f6f;
	background-color: #fff;
}

.ph-sct + .ph-sct {
	margin-top: 1.25em;
}

.ph-sct-otr {
	border-top: 1px solid transparent;
	background-color: #031f6f;
}

.ph-sct_bdy {
	max-width: 43.75em;
	margin: 0 auto;
}

.ph-sct p {
	margin-top: 1.25em;
}

@media screen and (max-width: 670px) {
	.ph-sct {
		padding: 1.42857em;
		border-width: 3px;
	}
	.ph-sct:first-child {
		margin-top: -4.64286em;
	}
	.ph-sct-otr {
		margin-top: 3.92857em;
		padding-bottom: 2.85714em;
	}
}

@media screen and (min-width: 671px) {
	.ph-sct {
		padding: 2.5em;
		border-width: 5px;
	}
	.ph-sct:first-child {
		margin-top: -6.25em;
	}
	.ph-sct-otr {
		margin-top: 5em;
		padding-bottom: 7.5em;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.ph-sct-otr {
		padding-bottom: 5em;
	}
}

.ph-ttl {
	text-align: center;
}

.ph-ttl_txt {
	display: block;
}

.ph-ttl_txt.-min {
	font-size: 1.5em;
}

.ph-ttl_txt.-lrg {
	margin-top: 0.44118em;
	font-size: 2.125em;
}

/* Training styles
   ========================================================================== */
.tr-dtl-sct {
	display: none;
}

.tr-dtl-sct.is-shw {
	display: block;
}

@media screen and (max-width: 670px) {
	.tr-dtl-sct {
		margin-top: 2.14286em;
	}
}

@media screen and (min-width: 671px) {
	.tr-dtl-sct {
		margin-top: 3.75em;
	}
}

/* FAQ detail styles
   ========================================================================== */
.faq-dtl {
	max-width: 62.5em;
	margin: 0 auto;
	line-height: 1.7;
	counter-increment: faq-idx;
}

.faq-dtl_trm, .faq-dtl_dsc {
	display: flex;
	align-items: center;
}

.faq-dtl_trm::before, .faq-dtl_dsc::before {
	content: '';
	width: 7.5em;
	height: 7.5em;
	border-radius: 50%;
	background-size: 100% 100%;
	overflow: hidden;
	display: block;
	flex-shrink: 0;
	align-self: flex-start;
}

.faq-dtl_trm {
	color: #234e89;
	line-height: 1.4;
}

.-career .faq-dtl_trm {
	color: #38679d;
}

.-handicapped .faq-dtl_trm,
.-other .faq-dtl_trm {
	color: #88a3ce;
}

.faq-dtl_trm::before {
	margin-right: 1.25em;
	background-image: url(../img/img_icn_qs_01.svg);
}

.-career .faq-dtl_trm::before {
	background-image: url(../img/img_icn_qs_02.svg);
}

.-handicapped .faq-dtl_trm::before,
.-other .faq-dtl_trm::before {
	background-image: url(../img/img_icn_qs_03.svg);
}

.faq-dtl_dsc {
	margin-top: 1.5em;
	color: #031f6f;
	flex-direction: row-reverse;
}

.-career .faq-dtl_dsc {
	color: #0f75be;
}

.-handicapped .faq-dtl_dsc,
.-other .faq-dtl_dsc {
	color: #4082ff;
}

.faq-dtl_dsc::before {
	margin-left: 1.25em;
	background-image: url(../img/img_icn_as_01.svg);
}

.-career .faq-dtl_dsc::before {
	background-image: url(../img/img_icn_as_02.svg);
}

.-handicapped .faq-dtl_dsc::before,
.-other .faq-dtl_dsc::before {
	background-image: url(../img/img_icn_as_03.svg);
}

.faq-dtl_blln {
	width: 100%;
	border-radius: 2em;
	background-color: #fff;
	display: flex;
	align-items: center;
	position: relative;
	z-index: 0;
}

.faq-dtl_blln::before {
	margin-right: 0.58824em;
	width: 1.35294em;
	height: 1.35294em;
	color: #fff;
	font-size: 1.6em;
	font-weight: 700;
	line-height: 1;
	text-align: center;
	border-radius: 0.29412em;
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
}

.faq-dtl_blln::after {
	content: '';
	width: 1.7em;
	height: 1.7em;
	background-image: url(../img/img_blln_itm_wht.svg);
	display: block;
	position: absolute;
	top: 0;
	z-index: -1;
}

.mce-content-body .faq-dtl_blln::after {
	background-image: url(../img/img_blln_itm_off_wht.svg);
}

.faq-dtl_blln.-qs {
	padding: 0.75em 1.5em;
}

.faq-dtl_blln.-qs::before {
	content: "Q" counter(faq-idx);
	width: 2.35294em;
	background-color: #234e89;
}

.-career .faq-dtl_blln.-qs::before {
	background-color: #38679d;
}

.-handicapped .faq-dtl_blln.-qs::before,
.-other .faq-dtl_blln.-qs::before {
	background-color: #88a3ce;
}

.faq-dtl_blln.-qs::after {
	left: -0.3em;
}

.faq-dtl_blln.-as {
	padding: 1.5em;
}

.faq-dtl_blln.-as::before {
	content: 'A';
	background-color: #031f6f;
	align-self: flex-start;
}

.-career .faq-dtl_blln.-as::before {
	background-color: #0f75be;
}

.-handicapped .faq-dtl_blln.-as::before,
.-other .faq-dtl_blln.-as::before {
	background-color: #4082ff;
}

.faq-dtl_blln.-as::after {
	right: -0.3em;
	transform: scale(-1, 1);
}

.mce-content-body .faq-dtl_blln {
	background-color: #f1f1f1;
}

@media screen and (max-width: 670px) {
	* + .faq-dtl {
		margin-top: 2.85714em;
	}
}

@media screen and (max-width: 425px) {
	.faq-dtl {
		font-size: 3.29412vw;
	}
	.faq-dtl_trm::before, .faq-dtl_dsc::before {
		width: 5.71429em;
		height: 5.71429em;
	}
}

@media screen and (min-width: 671px) {
	* + .faq-dtl {
		margin-top: 5em;
	}
	.faq-dtl_trm {
		max-width: 50em;
	}
	.faq-dtl_dsc {
		max-width: 51.75em;
		margin-left: auto;
	}
	.faq-dtl_blln {
		font-size: 1.25em;
	}
}
