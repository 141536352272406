// Extend styles
// =============================================================================
%extend {
	&--inner-box-1000 {
		@include mainCntWidth01(1000px + $pc-pad * 2);
	}
	&--inner-box-1100 {
		@include mainCntWidth01(1100px + $pc-pad * 2);
	}
	&--inner-box-1200 {
		@include mainCntWidth01(1200px + $pc-pad * 2);
	}
	&--add-box-shdw {
		box-shadow: 0 1.6em 1.6em -1.2em rgba($siteCol-nvy01, 0.1);
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&--px-axis,
		&--inner-box-1000,
		&--inner-box-1100,
		&--inner-box-1200 {
			padding: {
				right: $mbl-pad;
				left: $mbl-pad;
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&--px-axis,
		&--inner-box-1000,
		&--inner-box-1100,
		&--inner-box-1200 {
			padding: {
				right: $pc-pad;
				left: $pc-pad;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		&--px-axis,
		&--inner-box-1000,
		&--inner-box-1100,
		&--inner-box-1200 {
			padding: {
				right: $tblt-pad;
				left: $tblt-pad;
			}
		}
	}
}