@charset "utf-8";

@import "variables";

/* ==============================================================================================
   TinyMCE4 custom styles
   ============================================================================================== */

/* Extend styles
   ========================================================================== */
@import "extend";

/* Icon styles
   ========================================================================== */
@import "icn";

/* Editor styles
   ========================================================================== */
.mce-content-body {
	padding: 2em 4%;
	font: {
		family: $ffTyp01;
		size: 1.6rem;
		weight: $baseFwn;
	}
	line-height: 1.9;
	text: {
		align: justify;
		justify: inter-ideograph; // for IE //
	}
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

/* General styles
   ========================================================================== */
.mce-content-body,
.is-use-tinymce {
	* + p {
		margin-top: #{2 / $basePcFz + 0em};
	}
}

.tr-tbl {
	.mce-content-body & {
		display: none;
	}
}

.prm-box-ttl {
	color: $siteCol-f-wht;
	padding: 1.4 / 3.4 + 0em 2.5 / 3.4 + 0em;
	background-color: $siteCol-d-blu01;
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		* + & {
			margin-top: 3 / 2.4 + 0em;
		}
		font-size: #{2.4 / $baseMblFz + 0em};
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		* + & {
			margin-top: 6 / 3.4 + 0em;
		}
		font-size: #{3.4 / $basePcFz + 0em};
	}
}
.scnd-box-ttl {
	color: $siteCol-f-wht;
	text-align: center;
	padding: 0 2.5 / 3.4 + 0em;
	background-color: $siteCol-d-blu01;
	.-career & {
		background-color: $siteCol-blu03;
	}
	.-handicapped &,
	.-other & {
		background-color: $siteCol-blu04;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		font-size: #{2.4 / $baseMblFz + 0em};
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		font-size: #{3.4 / $basePcFz + 0em};
	}
}
.mrkr-ttl {
	color: $siteCol-f-wht;
	line-height: 1.8;
	&_txt {
		background-color: $siteCol-d-blu01;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		font-size: #{2.4 / $baseMblFz + 0em};
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		font-size: #{5.4 / $basePcFz + 0em};
	}
}

/* Accordion styles
   ========================================================================== */
.prm-acrd {
	&_trg {
		padding: 1 / 2.4 + 0em 0;
		color: $siteCol-d-blu01;
		font: {
			size: #{2.4 / $basePcFz + 0em};
			weight: $fwNsnsJp-bold;
		}
		display: flex;
		align-items: center;
		&-txt {
			display: block;
		}
		.mce-content-body & {
			pointer-events: none;
		}
	}
	&_tgl {
		width: 3 / 2.4 + 0em;
		height: 3 / 2.4 + 0em;
		margin-right: 1.5 / 2.4 + 0em;
		text-align: center;
		background-color: currentColor;
		overflow: hidden;
		display: block;
		flex-shrink: 0;
		align-self: baseline;
		position: relative;
		&::before {
			content: '\e902';
			color: $siteCol-f-wht;
			font-size: 3 / 2.4 + 0em;
			@include useIcomoon;
			display: block;
			position: absolute;
			top: $baseCenterPos;
			left: $baseCenterPos;
			transform: translate3d(-#{$baseCenterPos}, -#{$baseCenterPos}, 0);
			.is-load & {
				transition: transform $ovrDur $easeOutQuint;
			}
			.is-opn &,
			.mce-content-body & {
				transform: 
					translate3d(-#{$baseCenterPos}, -#{$baseCenterPos}, 0)
					rotate(45deg);
			}
		}
	}
	&_cnt {
		background-color: $siteCol-wht01;
		.is-use-tinymce & {
			display: none;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		* + & {
			margin-top: #{2 / $baseMblFz + 0em};
		}
		&_cnt {
			padding: #{2 / $baseMblFz + 0em};;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		* + & {
			margin-top: #{3 / $basePcFz + 0em};
		}
		&_cnt {
			padding: #{3 / $basePcFz + 0em};;
		}
	}
}

/* Philosophy styles
   ========================================================================== */
.ph-slgn {
	max-width: #{100 / $basePcFz + 0em};
	margin: 0 auto;
}

.ph-intr {
	font: {
		size: #{2 / $basePcFz + 0em};
		weight: $fwNsnsJp-bold;
	}
	text-align: center;
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		font-size: 17.5 / 425 * 100vw;
	}
}

.ph-sct {
	max-width: #{90 / $basePcFz + 0em};
	margin: 0 auto;
	color: $siteCol-d-blu01;
	border: solid $siteCol-d-blu01;
	background-color: $siteCol-f-wht;
	& + & {
		margin-top: #{2 / $basePcFz + 0em};
	}
	&-otr {
		@extend %extend--px-axis;
		border-top: 1px solid transparent;
		background-color: $siteCol-d-blu01;
	}
	&_bdy {
		max-width: #{70 / $basePcFz + 0em};
		margin: 0 auto;
	}
	p {
		margin-top: #{2 / $basePcFz + 0em};
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: #{2 / $baseMblFz + 0em};
		border-width: 3px;
		&:first-child {
			margin-top: -#{6.5 / $baseMblFz + 0em};
		}
		&-otr {
			margin-top: #{5.5 / $baseMblFz + 0em};
			padding-bottom: #{4 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: #{4 / $basePcFz + 0em};
		border-width: 5px;
		&:first-child {
			margin-top: -#{10 / $basePcFz + 0em};
		}
		&-otr {
			margin-top: #{8 / $basePcFz + 0em};
			padding-bottom: #{12 / $basePcFz + 0em};
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		&-otr {
			padding-bottom: #{8 / $basePcFz + 0em};
		}
	}
}

.ph-ttl {
	text-align: center;
	&_txt {
		display: block;
		&.-min {
			font-size: #{2.4 / $basePcFz + 0em};
		}
		&.-lrg {
			margin-top: 1.5 / 3.4 + 0em;
			font-size: #{3.4 / $basePcFz + 0em};
		}
	}
}

/* Training styles
   ========================================================================== */
.tr-dtl-sct {
	display: none;
	&.is-shw {
		display: block;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		& {
			margin-top: #{3 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		& {
			margin-top: #{6 / $basePcFz + 0em};
		}
	}
}

/* FAQ detail styles
   ========================================================================== */
.faq-dtl {
	max-width: #{100 / $basePcFz + 0em};
	margin: 0 auto;
	line-height: 1.7;
	counter-increment: faq-idx;
	&_trm,
	&_dsc {
		display: flex;
		align-items: center;
		&::before {
			content: '';
			width: #{12 / $basePcFz + 0em};
			height: #{12 / $basePcFz + 0em};
			border-radius: 50%;
			background-size: 100% 100%;
			overflow: hidden;
			display: block;
			flex-shrink: 0;
			align-self: flex-start;
		}
	}
	&_trm {
		color: $siteCol-l-blu05;
		line-height: 1.4;
		.-career & {
			color: $siteCol-blu02;
		}
		.-handicapped &,
		.-other & {
			color: $siteCol-ash-blu02;
		}
		&::before {
			margin-right: #{2 / $basePcFz + 0em};
			background-image: imgUrl('img_icn_qs_01.svg');
			.-career & {
				background-image: imgUrl('img_icn_qs_02.svg');
			}
			.-handicapped &,
			.-other & {
				background-image: imgUrl('img_icn_qs_03.svg');
			}
		}
	}
	&_dsc {
		margin-top: 3 / 2 + 0em;
		color: $siteCol-d-blu01;
		flex-direction: row-reverse;
		.-career & {
			color: $siteCol-blu03;
		}
		.-handicapped &,
		.-other & {
			color: $siteCol-blu04;
		}
		&::before {
			margin-left: #{2 / $basePcFz + 0em};
			background-image: imgUrl('img_icn_as_01.svg');
			.-career & {
				background-image: imgUrl('img_icn_as_02.svg');
			}
			.-handicapped &,
			.-other & {
				background-image: imgUrl('img_icn_as_03.svg');
			}
		}
	}
	&_blln {
		width: 100%;
		border-radius: 4 / 2 + 0em;
		background-color: $siteCol-f-wht;
		display: flex;
		align-items: center;
		position: relative;
		z-index: 0;
		&::before {
			margin-right: 2 / 3.4 + 0em;
			width: 4.6 / 3.4 + 0em;
			height: 4.6 / 3.4 + 0em;
			color: $siteCol-f-wht;
			font: {
				size: 3.2 / 2 + 0em;
				weight: $fwNsrfJp-bold;
			}
			line-height: 1;
			text-align: center;
			border-radius: 1 / 3.4 + 0em;
			display: flex;
			flex-shrink: 0;
			align-items: center;
			justify-content: center;
		}
		&::after {
			content: '';
			width: 3.4 / 2 + 0em;
			height: 3.4 / 2 + 0em;
			background-image: imgUrl('img_blln_itm_wht.svg');
			display: block;
			position: absolute;
			top: 0;
			z-index: -1;
			.mce-content-body & {
				background-image: imgUrl('img_blln_itm_off_wht.svg');
			}
		}
		&.-qs {
			padding: 1.5 / 2 + 0em 3 / 2 + 0em;
			&::before {
				content: 'Q'counter(faq-idx);
				width: 8 / 3.4 + 0em;
				background-color: $siteCol-l-blu05;
				.-career & {
					background-color: $siteCol-blu02;
				}
				.-handicapped &,
				.-other & {
					background-color: $siteCol-ash-blu02;
				}
			}
			&::after {
				left: -0.6 / 2 + 0em;
			}
		}
		&.-as {
			padding: 3 / 2 + 0em;
			&::before {
				content: 'A';
				background-color: $siteCol-d-blu01;
				align-self: flex-start;
				.-career & {
					background-color: $siteCol-blu03;
				}
				.-handicapped &,
				.-other & {
					background-color: $siteCol-blu04;
				}
			}
			&::after {
				right: -0.6 / 2 + 0em;
				transform: scale(-1, 1);
			}
		}
		.mce-content-body & {
			background-color: $siteCol-wht01;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		* + & {
			margin-top: #{4 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		font-size: 14 / 425 * 100vw;
		&_trm,
		&_dsc {
			&::before {
				width: #{8 / $baseMblFz + 0em};
				height: #{8 / $baseMblFz + 0em};
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		* + & {
			margin-top: #{8 / $basePcFz + 0em};
		}
		&_trm {
			max-width: #{80 / $basePcFz + 0em};
		}
		&_dsc {
			max-width: #{82.8 / $basePcFz + 0em};
			margin-left: auto;
		}
		&_blln {
			font-size: #{2 / $basePcFz + 0em};
		}
	}
}