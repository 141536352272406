// Icon styles
// =============================================================================
@font-face {
	src:
		url('../fonts/icomoon.ttf?#{$icmnId}') format('truetype'),
		url('../fonts/icomoon.woff?#{$icmnId}') format('woff'),
		url('../fonts/icomoon.svg?#{$icmnId}#icomoon') format('svg');
	font: {
		family: 'icomoon';
		style: normal;
		weight: normal;
		display: block;
	}
}

.icn {
	@include useIcomoon;
	@each $icnName, $icnId in $icnArr {
		&.-#{$icnName} {
			&::before {
				content: #{$quot}#{$icnId}#{$quot};
			}
		}
	}
}